import Landing from './views/pages/Landing'
import './App.css';

function App() {
  return (
    <Landing />
  );
}

export default App;
